import React, { useState } from 'react';
import axios from 'axios'

const Axios = axios.create({
    // baseURL: "http://localhost:5000",
    baseURL: "https://api.without143.com",
})

const isObject = (data) => {
    try {
        if (typeof data === "object") {
            return true;
        }
        return false;

    } catch (error) {
        return false;
    }
};

const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

const GenerateBase64 = async (file) => {
    let ImageBase64;
    if (isObject(file)) {
        ImageBase64 = await convertToBase64(file);
    } else {
        ImageBase64 = file;
    }
    return ImageBase64;
};

function PictureUpload() {

    const [photo, setPhoto] = useState();

    const handleDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file?.size > 3 * 1024 * 1024) {
            alert("Please upload less than 3mb image")
            setPhoto("")
            return;
        }
        if (file) {
            const objectURL = URL.createObjectURL(file); // Access it through the window object
            // const objectURL = window.URL.createObjectURL(file); // Access it through the window object
            const newFile = Object.assign(file, {
                preview: objectURL,
            });
            setPhoto(file);
        }
    };
    console.log(photo)

    const submitBtn = async () => {
        if (photo) {
            const resultPhoto = await GenerateBase64(photo)
            console.log(resultPhoto)

            const payload = {
                userImgHalfName: photo?.name,
                userImgHalf: resultPhoto,
            };

            console.log(payload)
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1MWJlOWUyZjc0YTQzNDE2NjNlNTBjYSIsImlhdCI6MTY5NjMyODE2Mn0.JOZvwF_DUzH42HOrJhRl34MhBlZ8bOmY20PclAik30M`
                },
                withCredentials: true
            };
            try {
                const { data } = await Axios.put("/api/user/updateMyProfile", payload, config);
                console.log(data)
            } catch (error) {
                console.log(error)
            }
        }

    }
    return (
        <div>
            upload photo: <input
                onChange={(e) => handleDrop(e.target.files)}
                type='file'
                accept=".jpg, .jpeg, .png"
            />
            {photo && (
                <div>
                    Selected file: {photo.name} ({Math.round(photo.size / 1024)} KB)
                    <img style={{ width: "300px", height: "300px" }} src={photo?.preview} alt="Preview" />
                </div>
            )}

            <button onClick={submitBtn}>Submit</button>
        </div>
    );
}

export default PictureUpload;
