import React, { useState } from 'react';
import "../styles/navbar.css"
import { Link } from 'react-router-dom';

function Navbar() {

    return (
        <div className='container'>
            <div className='logo'>
                <img src='images/logo12.png' className='logoField' />
                {/* <div className='logoName'>without143</div> */}
            </div>
            <button>
                {/* <div className='logoName'> */}

                <Link
                    className='logoName'
                    target='_blank'
                    style={{
                        textDecoration: 'none'
                    }}
                    to="https://play.google.com/store/apps/details?id=app.without143">

                    Download App
                </Link>

                {/* </div> */}
            </button >
        </div >
    );
}

export default Navbar;
