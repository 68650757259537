import React, { useState } from 'react';
import "../styles/contactUs.css"
import { Link, useNavigate } from 'react-router-dom';


function ContactUs() {

    const navigate = useNavigate();

    return (
        <div className='contactUs'>
            <div className='header'>
                Contact Us
            </div>
            <div style={{
                border: "0.2px solid lightblue",
                padding: "10px"
            }}
                className='innerSecond'
            >
                Email: without143app@gmail.com
            </div>
            <div style={{
                border: "0.2px solid lightblue",
                padding: "10px"
            }}
                className='innerSecond'
                onClick={() => {
                    alert("You can mail your request on without143app@gmail.com")
                }}
            >
                Verify Your Dating Account
            </div>
            <div className='header'>
                Privacy Policy
            </div>
            <div style={{
                border: "0.2px solid lightblue",
                padding: "10px"
            }}
                className='innerSecond'
                onClick={() => {
                    navigate("/privacy-policy")
                }}
            >
                Privacy Policy
            </div>

            <div className='header'>
                Connect With Us
            </div>
            <div>
                <a href='https://www.instagram.com/without143com' target='_blank'>
                    <img
                        style={{
                            height: "50px",
                            width: "50px"
                        }}
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/58/Instagram-Icon.png/1024px-Instagram-Icon.png" />
                </a>
            </div>
            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                <a href='https://t.me/+ZbY_MnYXBjtiZmE1' target='_blank'>
                    <img
                        style={{
                            height: "50px",
                            width: "50px"
                        }}
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/2048px-Telegram_logo.svg.png" />
                </a>
                <div
                    className='innerSecond'

                >
                    India
                </div>
            </div>
            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                <a href='https://t.me/+kx9etSc5IsQ0ZTA1' target='_blank'>
                    <img
                        style={{
                            height: "50px",
                            width: "50px"
                        }}
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/2048px-Telegram_logo.svg.png" />
                </a>
                <div
                    className='innerSecond'

                >
                    Korba (Chhattisgarh)
                </div>
            </div>

            <div className='header'>
                Alert!
            </div>
            <div style={{
                border: "0.2px solid lightblue",
                padding: "10px"
            }}
                className='innerSecond'>
                <div>
                    Don't share your privacy like name, phone number with anyone.
                </div>
                <div>
                    Don't send money to anyone if you have not met previously or do not know to each other
                </div>
            </div>

        </div>
    );
}

export default ContactUs;