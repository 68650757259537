import React, { useState } from 'react';
import "./App.css"
import HomePage from "./pages/home/HomePage"
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy"
// import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import PictureUpload from './pages/PictureUpload';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ContactUs from './components/ContactUs';
import Image from './pages/images/Image';


function App() {

  return (
    <Router>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/contactUs' element={<ContactUs />} />
        <Route path='/imagesBox' element={<Image />} />

      </Routes>
    </Router>
    // <HomePage />
    // <PictureUpload />
  );
}

export default App;
