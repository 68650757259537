import React, { useState } from 'react';
import "./HomePage.css"
import Navbar from "../../components/Navbar"
import Footer from '../../components/Footer';
import ImagesPortion from '../../components/ImagesPortion';

function HomePage() {

    return (
        <div className='home'>
            <Navbar />
            <div class="homepage">
                <div>
                    <img className='coverImg' src='images/coverImage-2.webp' />
                    {/* <img className='coverImg' src='images/coverImage-3.png' /> */}
                </div>
                <div class="content">
                    <h1>Have Money, Get Honey!</h1>
                </div>
            </div>
            <ImagesPortion />
            <Footer />
        </div>
    );
}

export default HomePage;
