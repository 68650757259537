import React, { useState } from 'react';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import "../styles/footer.css"

function Footer() {

    return (
        <div className='footer'>
            <AboutUs />
            <ContactUs />
        </div>
    );
}

export default Footer;
