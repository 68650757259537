import React from 'react'

function PrivacyPolicy() {
    return (
        <div style={{
            color: "whitesmoke",
            marginLeft: "15px"
        }}>

            <h2>Privacy Policy</h2>
            <p>
                This Privacy Policy explains how we
                collect, use, and protect your data
                when you use the without143 Mobile Application ("the App").
                By installing and using the App, you agree to the practices outlined in this policy.
            </p>
            <h3>
                1. Information Collection
            </h3>
            <h4>
                Location:
            </h4>
            <p>
                The App may collect your approximate location
                to provide location-based services, such as finding
                nearby merchants or services. We will request your
                permission before accessing precise location data.
            </p>

            <h4>
                Personal Information:
            </h4>
            <p>
                We may collect certain personal information,
                including your name, email address, phone number,
                and other details necessary for account creation and
                secure transaction processing. Rest assured that your
                personal information is stored securely and used only as outlined in this policy.
            </p>

            <h4>
                Files and Documents:
            </h4>
            <p>
                The App may request access to files
                and documents stored on your device to
                enable functionalities like uploading
                photos. We will seek your
                explicit permission before accessing these
                files and use them solely for the intended purposes of the App.
            </p>

            <h4>
                Calendar Events:
            </h4>
            <p>
                With your consent, the App may access your
                calendar events to schedule reminders or
                provide relevant features to enhance your experience.
            </p>


            <h3>
                2. Use of Information
            </h3>
            <p>
                The information we collect is used to offer you a
                seamless and personalized experience while using the App.
                We use your data for the following purposes:
            </p>
            <div style={{
                marginLeft: "10px"
            }}>
                <h5>
                    - Account creation and management
                </h5>
                <h5>
                    - Secure payment processing
                </h5>
                <h5>
                    - Providing customer support
                </h5>
                <h5>
                    - Sending important service-related communications
                </h5>
                <h5>
                    - Personalizing your experience and displaying relevant content
                </h5>
                <h5>
                    - Analyzing App usage to improve our services and features.
                </h5>
            </div>
            <h3>
                3. Mobile Application Permissions
            </h3>
            <h4>
                The without143 Mobile Application requires the following permissions on your device:
            </h4>

            <div style={{
                marginLeft: "10px"
            }}>
                <h5>
                    - Approximate Location:
                </h5>
                <p>
                    This permission allows us to provide
                    location-based services to help you find
                    nearby merchants or services for a better experience.
                </p>

                <h5>
                    - Personal Information:
                </h5>
                <p>
                    : We request permission to
                    access your name, email, and
                    other relevant information to create and
                    manage your account and facilitate secure transactions.
                </p>

                <h5>
                    - Files and Documents:
                </h5>
                <p>
                    We ask for access to files and documents on your
                    device to enable you to upload receipts or invoices
                    and provide seamless payment processing.
                </p>

                <h5>
                    - Calendar Events:
                </h5>
                <p>
                    With your consent,
                    we access your calendar events to improve your experience through
                    reminders and relevant features.
                </p>
                <h5>
                    - Camera :
                </h5>
                <p>
                    As We are offering video call functionality so to enable this features with
                    your device to transmit your video with your receiver, we ask for this permission
                </p>
                <h5>
                    - Microphone and Audio and Record Audio :
                </h5>
                <p>
                    As We are offering video call with audio functionality so to enable this features with
                    your device to transmit your video with your voice with your receiver, we ask for this permission
                </p>
                <h5>
                    - Notification :
                </h5>
                <p>
                    As We are offering Messaging Functionality, video call with audio functionality so to enable this features with
                    your device to get Notification for each communication, we ask for this permission.
                </p>

            </div>

            <h3>
                4. Data Security
            </h3>
            <p>
                At without143, we prioritize the security of your data.
                We have implemented appropriate measures to safeguard your
                personal information from unauthorized access, alteration,
                disclosure, or destruction. However, it's essential to note
                that no method of data transmission over the internet or
                electronic storage is entirely secure, and we cannot guarantee absolute security.
            </p>

            <h3>
                5. Your Choices
            </h3>
            <p>
                You have the right to:
            </p>

            <div style={{
                marginLeft: "10px"
            }}>
                <h5>
                    - Access, update, or delete your personal information.
                </h5>

                <h5>
                    - Opt-out of marketing communications.
                </h5>

                <h5>
                    - Control App permissions through your device settings.
                </h5>

                <h5>
                    - Request information about our data practices.
                </h5>

            </div>

            <h3>
                6. Children's Privacy
            </h3>
            <p>
                The without143 Mobile Application is not intended for use by children under the age of 18. We do not knowingly collect personal information from children. If you believe we have inadvertently collected information from a child, please contact us immediately.
            </p>

            <h3>
                7. Changes to the Privacy Policy
            </h3>
            <p>
                We may update this Privacy Policy periodically to reflect changes in our data practices. The updated version will be available on our website and within the App. We encourage you to review it regularly.
            </p>

            <h3>
                8. Contact Us
            </h3>
            <p>
                If you have any questions, concerns, or requests regarding this Privacy Policy or your data, please contact us at:
            </p>

            <h2>
                without143 Customer Support
            </h2>

            <h2>
                Email: without143app@gmail.com
            </h2>

            {/* Phone: +61 404 225 216 */}

            <h2>
                By installing and using the without143 Mobile Application, you consent to the collection, use, and sharing of your information as described in this Privacy Policy
            </h2>
        </div>
    )
}

export default PrivacyPolicy