import React from 'react'

function ImagesPortionInner({ key, stateCity, code, src1, src2 }) {
    const perImage = {
        height: "500px",
        width: "300px",
        margin: "10px",
        padding: "10px"
    }
    return (
        <div key={key}>
            <div style={{
                fontSize: "25px"
            }}><span style={{ color: "whitesmoke" }}> {stateCity}:</span></div>

            <div style={{
                marginLeft: "20px",
                display: "flex"
            }}>
                <div style={{
                    margin: "20px"
                }}>
                    <div style={{
                        fontSize: "20px",
                        fontWeight: 900
                    }}><span style={{ color: "white" }}>Code: {code}</span></div>
                    <div style={{
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        {src1 && <img style={perImage} src={src1} />}
                        {src2 && <img style={perImage} src={src2} />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImagesPortionInner