import React from 'react'
import ImagesPortionInner from '../../components/ImagesPortionInner'
import allGirlsImagesJson from '../../allGirlsImages.json';

function Image() {
    const perImage = {
        height: "500px",
        width: "300px"
    }


    return (
        <div>
            {
                allGirlsImagesJson?.map((e) => (
                    <ImagesPortionInner key={e?.id} stateCity={e?.stateCity} code={e?.code} src1={e?.src1} src2={e?.src2} />
                ))
            }
            {/* <div style={{
                fontSize: "50px"
            }}><span style={{ color: "whitesmoke" }}> CG_Korba:</span></div>

            <div style={{
                marginLeft: "20px",
                display: "flex"
            }}>
                <div style={{
                    margin: "20px"
                }}>
                    <div style={{
                        fontSize: "20px",
                        fontWeight: 900
                    }}><span style={{ color: "white" }}>Code: cgkrb1</span></div>
                    <img style={perImage} src='https://without143.s3.ap-south-1.amazonaws.com/girls/cg_korba/WhatsApp+Image+2024-02-20+at+7.52.48+AM.jpeg' />
                </div>
            </div> */}
        </div>
    )
}

export default Image