import React, { useState } from 'react';
import "../styles/aboutUs.css"

function AboutUs() {

    return (
        <div className='aboutUs'>
            <div className='header'>
                About Us
            </div>
            <div
                className='parent'
            >
                <strong className='strong'>18+</strong>
                <div className='innerFirst'>This is not simple Dating App But</div>
                <div className='innerSecond'>
                    India's First App Where You can physically meet with the people !
                </div>
                <div className='innerSecond'>
                    Tired of endlessly swiping on dating apps? 📱😩 Let's switch it up with #Without143!
                    🌟 Get straight to what you're after, no more wasted time – just results! ✨
                    Find exactly what you want, no fuss, no delay.
                    Time to make connections that truly matter! 💖
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
