import React from 'react'

import { Link, useNavigate } from 'react-router-dom';

function ImagesPortion() {
    const navigate = useNavigate();
    return (
        <div onClick={() => {
            navigate("/imagesBox")
        }} className='header parent' style={{ margin: '20px', cursor: "pointer" }}>ImagesPortion</div>
    )
}

export default ImagesPortion